import React, { lazy, Suspense } from "react";
import ReactDOM from 'react-dom/client';
import "./index.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";

import  NavigationSetter  from "./utils/services/GlobalNavigation/NavigationSetter";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { ChainProvider } from '@cosmos-kit/react';
import { chains, assets } from 'chain-registry';
import { Chain } from '@chain-registry/types';
import { wallets as kepler } from '@cosmos-kit/keplr-extension';
import { wallets as leap } from "@cosmos-kit/leap-extension";
import { wallets as cosmostation } from "@cosmos-kit/cosmostation-extension";
import "@interchain-ui/react/styles";
const App = lazy(() => import("./App"));


const rootElement = document.getElementById("root");
if(rootElement){
  const root = ReactDOM.createRoot(rootElement);
  const validatedChains = chains.map(chain => ({
    ...chain,
    status: chain.status ?? '',  // Ensure `status` is always a string
    network_type: chain.network_type ?? ''  // Ensure `network_type` is always a string
  }));
root.render(
  <ChainProvider
  chains={validatedChains as unknown as (string | Chain)[]} 
  assetLists={assets} 
  wallets={[...kepler,...leap,...cosmostation]} 
>
<BrowserRouter>
    <NavigationSetter />
    <Tooltip id="tooltip" />
    <Provider store={store}>
      <SkeletonTheme baseColor="#b8b8b8d1" highlightColor="#00000021">
      <Suspense fallback={<></>}>
              <App />
            </Suspense>
      </SkeletonTheme>
    </Provider>
    <ToastContainer />
  </BrowserRouter>

</ChainProvider>
 );

}else{
  console.error("Root element not found");
}

