
function getCircularReplacer() {
  const seen = new WeakSet();
  return (key:any, value:any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return; // Remove circular references
      }
      seen.add(value);
    }
    return value;
  };
}
export class StateLoader {
  loadState(): any {
    try {
      const serializedState: string | null = localStorage.getItem(
        "files.lighthouse.storage/store"
      );
      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state: any): void {
    try {
      const serializedState: string = JSON.stringify(state,getCircularReplacer());
      localStorage.setItem("files.lighthouse.storage/store", serializedState);
    } catch (err) {
      console.log(`Error:${err}`, "error");
    }
  }

  initializeState(): any {
    return {};
  }
}
